<template>
<v-container class="goal-container pa-0">
  <div class="w-100 d-flex justify-start align-start">
		<div style="width: 200px;">
			<SubMenu v-bind:selObj="selObj" />
		</div>
			
    <div style="width:calc(100% - 200px);">
      <v-container>
        <v-tabs v-model="tab">
          <v-tab>
            <div>目标统计</div>
          </v-tab>
          <v-tab>
            <div>项目统计</div>
          </v-tab>
          <v-tab>
            <div>任务统计</div>
          </v-tab>
          <!-- <v-tab>
            <div>任务工时</div>
          </v-tab> -->
          <v-tab>
            <div>任务进展</div>
          </v-tab>
          <v-tab>
            <div>总结统计</div>
          </v-tab>
          <v-tab>
            <div>复盘</div>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <ObjectStatistics :selObj="selObj" />
          </v-tab-item>
          <v-tab-item>
            <ItemStatistics  :selObj="selObj" />
          </v-tab-item>
          <v-tab-item>
            <TaskStatistics  :selObj="selObj" />
          </v-tab-item>
					<!-- <v-tab-item>
            <WorkTimeStatistics  :selObj="selObj" />
          </v-tab-item> -->
					<v-tab-item>
            <ProgressStatistics  :selObj="selObj" />
          </v-tab-item>
					<v-tab-item>
            <ReportStatistics  :selObj="selObj" />
          </v-tab-item>
					<v-tab-item>
            <ReviewStatistics  :selObj="selObj" />
          </v-tab-item>
        </v-tabs-items>
				
      </v-container>
    </div>
  </div>
</v-container>
</template>

<script>
export default {
  components: {
    SubMenu: () => import('@/components/workingReport/SubMenu.vue'),
    ObjectStatistics: () => import('@/components/workingReport/ObjectStatistics.vue'),
		ItemStatistics: () => import('@/components/workingReport/ItemStatistics.vue'),
		TaskStatistics: () => import('@/components/workingReport/TaskStatistics.vue'),
		WorkTimeStatistics: () => import('@/components/workingReport/WorkTimeStatistics.vue'),
		ProgressStatistics: () => import('@/components/workingReport/ProgressStatistics.vue'),
		ReportStatistics:() => import('@/components/workingReport/ReportStatistics.vue'),
		ReviewStatistics:() => import('@/components/workingReport/ReviewStatistics.vue'),
  },
  data() {
    return {
      tab: null,
      selObj: {
        type: 1,
        tab: 1
      },
      showSubMenu: null,
      pageMode: false,
    }
  },
  created() {
    this.showSubMenu = true;
  },
  methods: {
    handle_changePage(chk) {
      this.pageMode = chk;
    },

  }
}
</script>

<style>
.goal-container {
  height: 100%;
  width: 100%;
}

</style>
